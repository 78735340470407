.loadingRoot {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.PatternImg1 {
  height: 85%;
  left: 0;
  top: 0;
  position: fixed;
}
.PatternImg2 {
  height: 85%;
  right: 0;
  bottom: 0;
  position: fixed;
}
.LogoImg {
  height: 50%;
}
.MiddleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.Middle_InnerConatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: 20%;
}
@media only screen and (max-width: 1200px) {
  .PatternImg1 {
    height: 40%;
  }
  .PatternImg2 {
    height: 60%;
  }
  .LogoImg {
    height: 20%;
  }
  .Middle_InnerConatiner {
    margin-top: 10%;
  }
}
