.container {
  height: 100vh;
  width: 100vw;
}
.innerContainer {
  bottom: 0;
  margin-bottom: 2%;
  width: 100%;
  height: 100vh;
}
.imgContainer {
  overflow: hidden;
  width: 100%;
}
#video {
  position: fixed;
  top: 0;
  left: 0;
  width: 15em;
  height: 10em;
  border: 2px solid #013072;
}
/* .videoCamIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 20%;
  color: white;
  border: 2px solid #013072;
  z-index: 1;
  background-color: black;
} */
.tutImage {
  height: 685px;
  width: inherit;
}
.closeBTN {
  border-radius: 50%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.closeBTN:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.EUIBTN {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
@media only screen and (max-width: 1200px) {
  #video {
    width: 10em;
    height: 10em;
  }
  .EUIBTN {
    padding: 0rem;
  }
  .imgContainer {
    overflow-y: auto;
  }
  .tutImage {
    height: 50%;
  }
  /* .videoCamIcon {
    width: 10em;
    height: 10em;
  } */
}
