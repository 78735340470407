.Container {
  width: 250px;
  font-family: Heebo;
  align-self: center;
}
.topHeader {
  font-size: 40px;
  font-family: Heebo;
}
.Avatar_Container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.Card {
  height: 100px;
}
.Info {
  font-size: 20px;
  font-family: Heebo;
}
.EndingLine {
  font-size: 15px;
  font-family: Heebo;
}
.Next_BTN {
  background-color: #013072;
  padding: 0.8rem;
  border: 0;
  color: white;
  width: 100%;
  font-size: 13px;
  font-family: Heebo;
}
.Next_BTN:hover {
  cursor: pointer;
  background-color: #032452;
}
.Next_BTN:disabled {
  cursor: default;
  background-color: #6d88ac;
}
.Card {
  display: flex;
  align-items: flex-end;
  border-radius: 3%;
  background-color: #e9e1dd;
}
.Card:hover {
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .Container {
    width: 100px;
  }
  .topHeader {
    font-size: 20px;
  }
  .Card {
    height: 50px;
  }
  .Info {
    font-size: 10px;
  }
  .EndingLine {
    font-size: 8px;
  }
  .Next_BTN {
    padding: 0.3rem;
    font-size: 8px;
  }
}
