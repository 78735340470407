.root1 {
  color: #6392c9 !important;
}
.Content-Container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 1200px) {
  .Content-Container {
    overflow-y: auto;
  }
}
