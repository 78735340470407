.ShareInput {
  width: 80%;
  padding: 2%;
  margin: 0;
  outline: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  backdrop-filter: saturate(180%) blur(5px);
  font-family: Source Sans Pro;
}
.copyButton {
  margin: 0;
  outline: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  backdrop-filter: saturate(180%) blur(5px);
  font-family: Source Sans Pro;
  cursor: pointer;
  width: 20%;
}
.copyButton:hover {
  background: rgba(70, 66, 66, 0.8);
}
