.ToolBar {
  position: sticky;
  width: 100%;
  display: flex;
  top: 0;
  height: 5vh;
  box-shadow: 0 2px 2px 0.5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  background-color: white;
  z-index: 999;
}
.Logo {
  object-fit: cover;
  height: 100%;
}
.Pattern {
  opacity: 0.3;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.LataLogo {
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 99;
}
.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Line1 {
  font-family: Heebo;
  font-size: 20px;
  color: #013072;
  font-weight: 300;
}
.Line2 {
  font-family: Baskervville;
  color: #db9a1c;
  font-size: 40px;
  font-weight: 400;
}
.Line3 {
  font-family: Heebo;
  width: 100%;
  word-wrap: normal;
  color: #013072;
  font-size: 16px;
  font-weight: 300;
}
.LineContainer {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
  flex-direction: column;
  text-align: left;
  gap: 10px;
}
.LoginLine {
  font-family: Heebo;
  word-wrap: normal;
  color: #013072;
  font-size: 16px;
  font-weight: 300;
}

.smallPanel {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: column;
  background: rgba(198, 213, 234, 0.3);
  border-radius: 69px;
  gap: 90px;
  padding: 2rem;
}

.buttons {
  width: 250px;
  height: 50px;
  background-color: #013072;
  border-radius: 7px;
  color: white;
  font-size: 1rem;
  font-family: Heebo;
  opacity: 1;
  /* margin-top: 1rem; */
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.buttons:hover {
  background-color: #15407e;
}
.buttons:disabled {
  background-color: #406499;
  cursor: default;
}

.panelHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.panel_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 30%;
}
.panle_Container_Text {
  word-wrap: normal;
  color: #013072;
  font-size: 19px;
  width: 60%;
  text-align: center;
  font-family: Heebo;
}

.feedbackInput {
  font-family: Heebo;
  z-index: 99;
  width: 60%;
  background: none;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: 2px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}
.feedbackInput::placeholder {
  color: White;
  opacity: 0.8;
}
.loginInput {
  font-family: Heebo;
  width: 80%;
  background: none;
  color: #013072;
  padding: 12px 20px;
  border: 3px solid #013072;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  height: 3rem;
}
.loginInput::placeholder {
  color: #013072;
  opacity: 0.8;
}
.advice {
  font-family: Heebo;
  color: #013072;
}

.inputHolder {
  margin-top: 10%;
  margin-left: 13%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 2%;
}
.getinTouchHeading {
  font-family: Baskervville;
  font-size: 40px;
}
.lastLine {
  font-family: Heebo;
  font-size: 15px;
}
.Lines {
  gap: 10px;
}
.SendButton {
  width: 150px;
  height: 50px;
  background: white;
  border-color: #013072;
  border-radius: 15px;
  color: #013072;
  font-size: 1rem;
  font-family: Heebo;
  font-weight: bold;
  letter-spacing: 1.5px;
  cursor: pointer;
  border: none;
}
.SendButton:disabled {
  background: lightgray;
  border-color: none;
  cursor: default;
}
.mailError {
  font-size: 1rem;
  color: red;
}
.feedbackMailErr {
  color: red;
  font-family: Heebo;
}
.panelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}
@media only screen and (max-width: 1200px) {
  .mailError {
    font-size: 0.5rem;
  }
  .Line1 {
    font-size: 8px;
  }
  .Line2 {
    font-size: 12px;
  }
  .Line3 {
    font-size: 6px;
    width: 100%;
  }
  .LineContainer {
    gap: 4px;
  }
  .panle_Container_Text {
    font-size: 8px;
  }
  .panelContainer {
    gap: 10px;
  }
  .buttons {
    font-size: 0.5rem;
    height: 30px;
    width: 90px;
    border-radius: 5px;
    /* margin-top: 0.5rem; */
    outline: none;
    letter-spacing: 0px;
  }
  .feedbackInput {
    width: 80%;
  }
  .feedbackMailErr {
    font-size: small;
  }
  .loginInput {
    height: 2rem;
    font-size: 0.6rem;
    border: 2px solid #013072;
  }

  .advice {
    font-size: 0.6rem;
  }
  .Lines {
    gap: 4px;
  }
  .LoginLine {
    font-size: 10px;
  }
  .smallPanel {
    gap: 30px;
    border-radius: 50px;
    padding: 1rem;
  }
  .getinTouchHeading {
    font-size: 20px;
  }
  .inputHolder {
    align-items: center;
    margin-left: 0%;
  }
  .lastLine {
    font-size: 10px;
  }
  .SendButton {
    height: 40px;
    width: 80px;
    border-radius: 12px;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
}
