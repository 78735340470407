@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baskervville&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
html {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}
#studio {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
}

#video {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#fullscreen {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Container {
  width: 250px;
  font-family: Heebo;
  align-self: center;
}
.topHeader {
  font-size: 40px;
  font-family: Heebo;
}
.Avatar_Container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.Card {
  height: 100px;
}
.Info {
  font-size: 20px;
  font-family: Heebo;
}
.EndingLine {
  font-size: 15px;
  font-family: Heebo;
}
.Next_BTN {
  background-color: #013072;
  padding: 0.8rem;
  border: 0;
  color: white;
  width: 100%;
  font-size: 13px;
  font-family: Heebo;
}
.Next_BTN:hover {
  cursor: pointer;
  background-color: #032452;
}
.Next_BTN:disabled {
  cursor: default;
  background-color: #6d88ac;
}
.Card {
  display: flex;
  align-items: flex-end;
  border-radius: 3%;
  background-color: #e9e1dd;
}
.Card:hover {
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .Container {
    width: 100px;
  }
  .topHeader {
    font-size: 20px;
  }
  .Card {
    height: 50px;
  }
  .Info {
    font-size: 10px;
  }
  .EndingLine {
    font-size: 8px;
  }
  .Next_BTN {
    padding: 0.3rem;
    font-size: 8px;
  }
}

.container {
  height: 100vh;
  width: 100vw;
}
.innerContainer {
  bottom: 0;
  margin-bottom: 2%;
  width: 100%;
  height: 100vh;
}
.imgContainer {
  overflow: hidden;
  width: 100%;
}
#video {
  position: fixed;
  top: 0;
  left: 0;
  width: 15em;
  height: 10em;
  border: 2px solid #013072;
}
/* .videoCamIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 20%;
  color: white;
  border: 2px solid #013072;
  z-index: 1;
  background-color: black;
} */
.tutImage {
  height: 685px;
  width: inherit;
}
.closeBTN {
  border-radius: 50%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.closeBTN:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.EUIBTN {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
}
@media only screen and (max-width: 1200px) {
  #video {
    width: 10em;
    height: 10em;
  }
  .EUIBTN {
    padding: 0rem;
  }
  .imgContainer {
    overflow-y: auto;
  }
  .tutImage {
    height: 50%;
  }
  /* .videoCamIcon {
    width: 10em;
    height: 10em;
  } */
}

.loadingRoot {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.PatternImg1 {
  height: 85%;
  left: 0;
  top: 0;
  position: fixed;
}
.PatternImg2 {
  height: 85%;
  right: 0;
  bottom: 0;
  position: fixed;
}
.LogoImg {
  height: 50%;
}
.MiddleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.Middle_InnerConatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  margin-top: 20%;
}
@media only screen and (max-width: 1200px) {
  .PatternImg1 {
    height: 40%;
  }
  .PatternImg2 {
    height: 60%;
  }
  .LogoImg {
    height: 20%;
  }
  .Middle_InnerConatiner {
    margin-top: 10%;
  }
}

.SelectButton {
  font-family: Roboto;
  width: 150px;
  height: 50px;
  border: none;
  color: white;
  background-color: #013072;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.8;
  font-size: 1rem;
  -webkit-backdrop-filter: saturate(180%) blur(2px);
          backdrop-filter: saturate(180%) blur(2px);
}
.SelectButton:hover {
  background-color: rgb(15, 63, 131);
}
.SelectButton:active {
  background-color: #072247;
}
.arrowButton {
  width: 50px;
  height: 50px;
  border: none;
  background-color: #436596;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.8;
  -webkit-backdrop-filter: saturate(180%) blur(2px);
          backdrop-filter: saturate(180%) blur(2px);
}
.arrowButton:hover {
  background-color: #577fb8;
}
.list {
  width: 218px;
  color: white;
  background-color: #013072;
  padding: 0;
  margin: 0;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #436596;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.8;
  -webkit-backdrop-filter: saturate(180%) blur(2px);
          backdrop-filter: saturate(180%) blur(2px);
}
.list:hover {
  background-color: rgb(15, 63, 131);
}
.unClickableList {
  width: 218px;
  color: white;
  background-color: #436596;
  padding: 0;
  margin: 0;
  padding: 1rem;
  text-align: center;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.8;
  -webkit-backdrop-filter: saturate(180%) blur(2px);
          backdrop-filter: saturate(180%) blur(2px);
}
.listHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2%;
}
@media only screen and (max-width: 1200px) {
  .SelectButton {
    width: 100px;
    height: 40px;
    font-size: 0.8rem;
  }
  .arrowButton {
    width: 40px;
    height: 40px;
  }
  .list {
    width: 148px;
    font-size: 0.8rem;
  }
  .unClickableList {
    width: 148px;
    font-size: 0.8rem;
  }
  .arrowButton:hover {
    background-color: none;
  }
  .list:hover {
    background-color: none;
  }
  .SelectButton:hover {
    background-color: none;
  }
  .listHolder {
    max-height: 200px;
    overflow-y: auto;
  }
}

.root1 {
  color: #6392c9 !important;
}
.Content-Container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 1200px) {
  .Content-Container {
    overflow-y: auto;
  }
}

.ShareInput {
  width: 80%;
  padding: 2%;
  margin: 0;
  outline: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  -webkit-backdrop-filter: saturate(180%) blur(5px);
          backdrop-filter: saturate(180%) blur(5px);
  font-family: Source Sans Pro;
}
.copyButton {
  margin: 0;
  outline: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  -webkit-backdrop-filter: saturate(180%) blur(5px);
          backdrop-filter: saturate(180%) blur(5px);
  font-family: Source Sans Pro;
  cursor: pointer;
  width: 20%;
}
.copyButton:hover {
  background: rgba(70, 66, 66, 0.8);
}

.ToolBar {
  position: sticky;
  width: 100%;
  display: flex;
  top: 0;
  height: 5vh;
  box-shadow: 0 2px 2px 0.5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  background-color: white;
  z-index: 999;
}
.Logo {
  object-fit: cover;
  height: 100%;
}
.Pattern {
  opacity: 0.3;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.LataLogo {
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 99;
}
.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Line1 {
  font-family: Heebo;
  font-size: 20px;
  color: #013072;
  font-weight: 300;
}
.Line2 {
  font-family: Baskervville;
  color: #db9a1c;
  font-size: 40px;
  font-weight: 400;
}
.Line3 {
  font-family: Heebo;
  width: 100%;
  word-wrap: normal;
  color: #013072;
  font-size: 16px;
  font-weight: 300;
}
.LineContainer {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
  flex-direction: column;
  text-align: left;
  grid-gap: 10px;
  gap: 10px;
}
.LoginLine {
  font-family: Heebo;
  word-wrap: normal;
  color: #013072;
  font-size: 16px;
  font-weight: 300;
}

.smallPanel {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: column;
  background: rgba(198, 213, 234, 0.3);
  border-radius: 69px;
  grid-gap: 90px;
  gap: 90px;
  padding: 2rem;
}

.buttons {
  width: 250px;
  height: 50px;
  background-color: #013072;
  border-radius: 7px;
  color: white;
  font-size: 1rem;
  font-family: Heebo;
  opacity: 1;
  /* margin-top: 1rem; */
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.buttons:hover {
  background-color: #15407e;
}
.buttons:disabled {
  background-color: #406499;
  cursor: default;
}

.panelHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.panel_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 30%;
}
.panle_Container_Text {
  word-wrap: normal;
  color: #013072;
  font-size: 19px;
  width: 60%;
  text-align: center;
  font-family: Heebo;
}

.feedbackInput {
  font-family: Heebo;
  z-index: 99;
  width: 60%;
  background: none;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: 2px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}
.feedbackInput::-webkit-input-placeholder {
  color: White;
  opacity: 0.8;
}
.feedbackInput:-ms-input-placeholder {
  color: White;
  opacity: 0.8;
}
.feedbackInput::placeholder {
  color: White;
  opacity: 0.8;
}
.loginInput {
  font-family: Heebo;
  width: 80%;
  background: none;
  color: #013072;
  padding: 12px 20px;
  border: 3px solid #013072;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  height: 3rem;
}
.loginInput::-webkit-input-placeholder {
  color: #013072;
  opacity: 0.8;
}
.loginInput:-ms-input-placeholder {
  color: #013072;
  opacity: 0.8;
}
.loginInput::placeholder {
  color: #013072;
  opacity: 0.8;
}
.advice {
  font-family: Heebo;
  color: #013072;
}

.inputHolder {
  margin-top: 10%;
  margin-left: 13%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 2%;
}
.getinTouchHeading {
  font-family: Baskervville;
  font-size: 40px;
}
.lastLine {
  font-family: Heebo;
  font-size: 15px;
}
.Lines {
  grid-gap: 10px;
  gap: 10px;
}
.SendButton {
  width: 150px;
  height: 50px;
  background: white;
  border-color: #013072;
  border-radius: 15px;
  color: #013072;
  font-size: 1rem;
  font-family: Heebo;
  font-weight: bold;
  letter-spacing: 1.5px;
  cursor: pointer;
  border: none;
}
.SendButton:disabled {
  background: lightgray;
  border-color: none;
  cursor: default;
}
.mailError {
  font-size: 1rem;
  color: red;
}
.feedbackMailErr {
  color: red;
  font-family: Heebo;
}
.panelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}
@media only screen and (max-width: 1200px) {
  .mailError {
    font-size: 0.5rem;
  }
  .Line1 {
    font-size: 8px;
  }
  .Line2 {
    font-size: 12px;
  }
  .Line3 {
    font-size: 6px;
    width: 100%;
  }
  .LineContainer {
    grid-gap: 4px;
    gap: 4px;
  }
  .panle_Container_Text {
    font-size: 8px;
  }
  .panelContainer {
    grid-gap: 10px;
    gap: 10px;
  }
  .buttons {
    font-size: 0.5rem;
    height: 30px;
    width: 90px;
    border-radius: 5px;
    /* margin-top: 0.5rem; */
    outline: none;
    letter-spacing: 0px;
  }
  .feedbackInput {
    width: 80%;
  }
  .feedbackMailErr {
    font-size: small;
  }
  .loginInput {
    height: 2rem;
    font-size: 0.6rem;
    border: 2px solid #013072;
  }

  .advice {
    font-size: 0.6rem;
  }
  .Lines {
    grid-gap: 4px;
    gap: 4px;
  }
  .LoginLine {
    font-size: 10px;
  }
  .smallPanel {
    grid-gap: 30px;
    gap: 30px;
    border-radius: 50px;
    padding: 1rem;
  }
  .getinTouchHeading {
    font-size: 20px;
  }
  .inputHolder {
    align-items: center;
    margin-left: 0%;
  }
  .lastLine {
    font-size: 10px;
  }
  .SendButton {
    height: 40px;
    width: 80px;
    border-radius: 12px;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
}

