.SelectButton {
  font-family: Roboto;
  width: 150px;
  height: 50px;
  border: none;
  color: white;
  background-color: #013072;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  font-size: 1rem;
  backdrop-filter: saturate(180%) blur(2px);
}
.SelectButton:hover {
  background-color: rgb(15, 63, 131);
}
.SelectButton:active {
  background-color: #072247;
}
.arrowButton {
  width: 50px;
  height: 50px;
  border: none;
  background-color: #436596;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  backdrop-filter: saturate(180%) blur(2px);
}
.arrowButton:hover {
  background-color: #577fb8;
}
.list {
  width: 218px;
  color: white;
  background-color: #013072;
  padding: 0;
  margin: 0;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #436596;
  user-select: none;
  opacity: 0.8;
  backdrop-filter: saturate(180%) blur(2px);
}
.list:hover {
  background-color: rgb(15, 63, 131);
}
.unClickableList {
  width: 218px;
  color: white;
  background-color: #436596;
  padding: 0;
  margin: 0;
  padding: 1rem;
  text-align: center;
  cursor: default;
  user-select: none;
  opacity: 0.8;
  backdrop-filter: saturate(180%) blur(2px);
}
.listHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2%;
}
@media only screen and (max-width: 1200px) {
  .SelectButton {
    width: 100px;
    height: 40px;
    font-size: 0.8rem;
  }
  .arrowButton {
    width: 40px;
    height: 40px;
  }
  .list {
    width: 148px;
    font-size: 0.8rem;
  }
  .unClickableList {
    width: 148px;
    font-size: 0.8rem;
  }
  .arrowButton:hover {
    background-color: none;
  }
  .list:hover {
    background-color: none;
  }
  .SelectButton:hover {
    background-color: none;
  }
  .listHolder {
    max-height: 200px;
    overflow-y: auto;
  }
}
